import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import CountUp from 'react-countup';
import { TypeAnimation } from 'react-type-animation';
import { animated, useTransition } from '@react-spring/web'
import './Slides.scss';

const SlideItem = ({item, containerClass, delay}) => {
  const keyName = Object.keys(item)[0];
  const value = Object.values(item)[0];
  const isImage = keyName.includes('image');
  const hasSparkles = keyName.includes('sparkles');
  const hasFlowers = keyName.includes('flowers');
  const isBigFlowers = keyName.includes('big_flowers');
  const hasStar = keyName.includes('star');
  const isTypeAnimation = keyName.includes('_typing');
  const keyId = uuidv4();
  let display;

  if (isBigFlowers) {
    return null;
  }


  let strippedName = keyName.split('_sparkles')[0].split('_flowers')[0];
  const hasEmphasis = value && typeof value === 'string' && value.includes('<span>');
  
  if (hasStar) {
    strippedName = strippedName.replace('_star', '')
  }

  if (isTypeAnimation) {
    strippedName = strippedName.replace('_typing', '')
  }

  let textContent = value;

  if (strippedName === 'unit' && value > 0) {
    textContent = (
      <CountUp
        end={value}
        duration={1.5}
      />
    )
  }

  if (isTypeAnimation) {
    textContent = (
      <TypeAnimation 
        sequence={[value]}
        repeat={1}
        cursor={false}
        speed={5}
      />
    )
  }

  const textContentWrapper = (
    <p key={keyId} className={`${containerClass}__${strippedName}`}>
      {textContent}
    </p>
  )
  
  let content = isImage ? (
    <img
      key={keyId}
      className={`${containerClass}__img`}
      src={value}
      alt="slide_image"
    />
  ) : textContentWrapper
  
  // to be able to have italics and bold within a single block
  if (hasEmphasis) {
    let before = value.split('<span>')[0];
    let after = value.split('</span>')[1];
    const emphasis = value.split('<span>')[1].split('</span>')[0];

    if (before.includes('<bold>')) {
      const beforeBold = before.split('<bold>')[0];
      const afterBold = before.split('</bold>')[1];
      const bold = before.split('<bold>')[1].split('</bold>')[0];
      before = (
        <>
          {beforeBold}
          <b>{bold}</b>
          {afterBold}
        </>
      )

    }
    if (after.includes('<bold>')) {
      const beforeBold = after.split('<bold>')[0];
      const afterBold = after.split('</bold>')[1];
      const bold = after.split('<bold>')[1].split('</bold>')[0];
      after = (
        <>
          {beforeBold}
          <b>{bold}</b>
          {afterBold}
        </>
      )
    }

    content = (
      <p key={keyId} className={`${containerClass}__${strippedName}`}>
        {before}
        <span>{emphasis}</span>
        {after}
      </p>
    )
  }

  if ((hasSparkles || hasFlowers) && isImage) {
    display = (
      <div key={keyId} className={hasSparkles ? `${containerClass}__sparkles` : `${containerClass}__flowers`}>
        <span />
        <span />
        <span />
        <span />
        {content}
      </div>
    )
  } else if (hasStar) {
    const animItems = [
      <span />,
      <div>{content}</div>
    ]

    const transitions = useTransition(animItems, {
      from: { opacity: 0, transform: 'scale(1.1)' },
      enter: { opacity: 1, transform: 'scale(1)' },
      trail: 300,
      delay,
    })

    const animatedBit = transitions((style, i) => {
      return (
        <animated.div style={style}>
          {i}
        </animated.div>
      )
    })

    display = (
      <div key={keyId} className={`${containerClass}__star`}>
        {animatedBit}
      </div>
    )
  } else if (hasSparkles && !hasFlowers && !isImage) {
    display = (
      <div key={keyId} className={`${containerClass}__${strippedName}__sparkles`}>
        <span />
        <span />
        <span />
        <span />
        {content}
      </div>
    )
  }
  else {
    display = content
  }

  return display;
}

export default SlideItem;


SlideItem.propTypes = {
  item: PropTypes.object.isRequired, 
  containerClass: PropTypes.string.isRequired,
  delay: PropTypes.number
}

SlideItem.defaultProps = {
  delay: 300
}
