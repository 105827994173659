import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import trinityBadge from './assets/trinity_badge.svg';

import './Slides.scss';

const SlideProgressItem = ({ item, containerClass, activeSlide }) => {
  const [valueEnd, setValueEnd] = React.useState(0);
  const [transitionDuration, setTransitionDuration] = React.useState(1);

  let value = 33;
  if (item.progress > 33 && item.progress < 65) {
    value = 66;
  }
  if (item.progress >= 65) {
    value = 85;
  }

  useEffect(() => {
    let timeoutId;
    if (activeSlide.name.includes('trinity_progress')) {
      timeoutId = setTimeout(() => {
        setTransitionDuration(1);
        setValueEnd(value);
      }, 500);
    } else {
      setTransitionDuration(0);
      setValueEnd(0);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [activeSlide]);

  return (
    <div className={`${containerClass}__progress`}>
      <div style={{ position: 'relative' }}>
        <img
          src={trinityBadge}
          alt="roset"
          style={{ width: 240, maxWidth: 240 }}
        />
        <CircularProgressbar
          value={valueEnd}
          styles={{
            root: {
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 159
            },
            path: {
              stroke: '#FFEE33',
              // Rotate the path
              transform: 'rotate(180deg)',
              transformOrigin: 'center center',
              transition: `stroke-dashoffset ${transitionDuration}s ease 0s`
            },
            trail: {
              stroke: 'rgba(0, 0, 0, 0.1)',
              // Rotate the trail
              transform: 'rotate(180deg)',
              transformOrigin: 'center center'
            }
          }}
        />
      </div>
    </div>
  );
};

export default SlideProgressItem;

SlideProgressItem.propTypes = {
  item: PropTypes.object.isRequired,
  containerClass: PropTypes.string.isRequired,
  activeSlide: PropTypes.object.isRequired
};
