import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import Vimeo from "@u-wave/react-vimeo";
import CookieUtils from '../../../utils/CookiesUtils';
import './Slides.scss';

const SlideVideoItem = ({item, containerClass, muted, triggerAudioTrackPause}) => {
  
  const [playerOpen, setPlayerOpen] = useState(false);
 
  const triggerOpen = () => {
    triggerAudioTrackPause(true)
    setPlayerOpen(true)
  }

  const triggerClose = () => {
    setPlayerOpen(false)
    triggerAudioTrackPause(false)
  }

  const renderThumbnail = () => {
    return (
      <button
        className={`${containerClass}__video__thumbnail`}
        style={{
          backgroundImage: `url(${item.thumbnail_src})`
        }}
        onClick={triggerOpen}
        type='button'
      />
    )
  }

  const renderVideo = () => {
    return (
      <Modal 
        open 
        onClose={triggerClose} 
        closeIcon 
        className={`${containerClass}__video__player`}
        transition='scale'
      >
        <Modal.Content>
          <Vimeo
            video={item.video}
            autoplay
            muted={muted}
            loop
            responsive
            controls
            dnt={!CookieUtils.analyticsConsentGiven()}
          />

        </Modal.Content>
      </Modal>
    )
  }

  return (
    <>
      {!playerOpen && renderThumbnail()}
      {playerOpen && renderVideo()}
    </>
  )
}

export default SlideVideoItem;

SlideVideoItem.propTypes = {
  muted: PropTypes.bool,
  item: PropTypes.object.isRequired,
  containerClass: PropTypes.string.isRequired,
  triggerAudioTrackPause: PropTypes.func.isRequired
}

SlideVideoItem.defaultProps = {
  muted: false
}
