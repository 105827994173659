import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import { useSwipeable } from 'react-swipeable';
import { useParams, Link } from 'react-router-dom';
import { ReactSVG } from "react-svg";
import paths from '../../consts/paths';
import Audio from './Audio';
import RoundUpAPI from '../../api/RoundupApi';
import GrownUpAPI from '../../api/GrownUpAPI';
import Stepper from './v2/Stepper';
import logo from './v2/assets/logo.svg';
import mute_on from './v2/assets/mute_on.svg';
import mute_off from './v2/assets/mute_off.svg';
import exit from './v2/assets/exit.svg';
import background from './v2/assets/background.png';
import background_highlight from './v2/assets/background_blue.png';
import WindowUtils from '../../utils/WindowUtils';
import SlidesV2 from './v2/Slides';
import audioTrack from './v2/assets/stay_wonderful.mp3';
import './Roundup.scss';

const RoundUp = () => {

  const releaseVersionTwo = process.env.REACT_APP_PHASE_2_ROUNDUP === 'true';
  const enableComingSoon = !releaseVersionTwo;

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [data, setData] = useState(null);
  const [roundUpId, setRoundUpId] = useState(null);
  const [slideTotal, setSlideTotal] = useState(0);
  const roundupRef = useRef();
  const { kid_id } = useParams();
  const [grownUpId, setGrownUpId] = useState(null);
  const [trackingId, setTrackingId] = useState(null);
  const [trackingSetUpLoading, setTrackingSetUpLoading] = useState(false);
  const [lastTrackingSlideIndex, setLastTrackingSlideIndex] = useState(0);
  const [slideTrackingData, setSlideTrackingData] = useState(null);
  const [firstPlay, setFirstPlay] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);

  const [playAudioTrack, setPlayAudioTrack] = useState(false);
  const [pauseAudioTrack, setPauseAudioTrack] = useState(false)
  const [fadeAudioTrack, setFadeAudioTrack] = useState(false);
  const [mutedAudioTrack, setMutedAudioTrack] = useState(true);
  const [highlightSlide, setHighlightSlide] = useState(false);

  const containerClass = highlightSlide ? 'RoundupHighlight' : 'Roundup';
  const showSlides = data && grownUpId && kid_id;

  // TRACKING - setup
  const trackingSetUp = async () => {
    const getTrackingId = await RoundUpAPI.postRoundUpViewing(slideTrackingData, grownUpId, roundUpId)
    setTrackingId(getTrackingId.id);
    setTrackingSetUpLoading(false);
  }

  // TRACKING - update (slide changed)
  const trackingUpdate = async (slide_designator, slide_number) => {
    return RoundUpAPI.updateRoundUpViewing(trackingId, slide_designator, slide_number)
  }

  const triggerTracking = () => {
    const newIndex = activeSlideIndex + 1;
    // only trigger tracking if slide is higher than tracking already recorded
    if (newIndex > lastTrackingSlideIndex) {
      const { slide_designators } = slideTrackingData;
      const slideName = slide_designators[newIndex];
      trackingUpdate(slideName, activeSlideIndex + 2) // plus 2 as BE not using index value here
      setLastTrackingSlideIndex(newIndex);
    } 
  }

  // CONTENT DATA - get
  const getRoundupData = async () => {
    return new Promise((resolve, reject) => {
      RoundUpAPI.getRoundUp(kid_id)
      .then((response) => {
        if (response) {
          const { id, slides, tracking } = response;
          setRoundUpId(id);
          setData(slides);
          setSlideTrackingData(tracking)
        }
        setDataLoading(false)
        resolve();
      })
      .catch(error => {
        reject(error)
        setDataLoading(false)
      })
    })
  }

  // HIDE CHAT WIDGET
  useEffect(() => {
    // Create a new mutation observer
    /* eslint-disable-next-line */
    const observer = new MutationObserver(() => {
      const launcher = document.getElementById('launcher');
      const widget = launcher?.parentNode;
      if (widget) {
        widget.style.cssText = 'display: none';
        // Once the widget is found and hidden, we can stop observing for changes
        observer.disconnect();
      }
    });
    // Start observing the document with the configured parameters
    observer.observe(document, { childList: true, subtree: true });
    // Clean up the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);


  // EXIT - redirect to timeline
  const triggerClose = () => {
    WindowUtils.replaceLocation(paths.TIMELINE(kid_id))
  }
  
  const fetchGrownUpFromToken = async () => {
    if (!grownUpId) {
      return new Promise((resolve, reject) => {
        GrownUpAPI.fetchGrownUpFromToken()
        .then((response) => {
          const { id } = response.data.data;
          setGrownUpId(id);
          setDataLoading(false)
          resolve();
        })
        .catch(error => {
          reject(error)
          setDataLoading(false)
        })
      })
    }   
    setDataLoading(false)
    return null;
  }
  
  useEffect(() => {
    setDataLoading(true)
    fetchGrownUpFromToken()
  }, [grownUpId])

  // Fetch the round-up data
  useEffect(() => {
    if (!data && kid_id && grownUpId) {
      setDataLoading(true)
      getRoundupData()
    }
  }, [data, kid_id, grownUpId])

  // Set up tracking
  useEffect(() => {
    if (data) {
      setSlideTotal(data.length);
      if (!trackingId && !trackingSetUpLoading && slideTrackingData && grownUpId && roundUpId) {
        setTrackingSetUpLoading(true)
        trackingSetUp()
      }
    }
  }, [data, trackingId, grownUpId, roundUpId, slideTrackingData, trackingSetUpLoading])

  useEffect(() => {
    if (slideTrackingData) {
      const {
        slide_designators
      } = slideTrackingData;
      const currentSlideName = slide_designators[activeSlideIndex];
      if (currentSlideName.includes('trinity')) {
        setHighlightSlide(true)
      } else {
        setHighlightSlide(false)
      }
    }
  }, [slideTrackingData, activeSlideIndex])
    
  // AUDIO 
  const onToggleSound = () => {
    setMutedAudioTrack(!mutedAudioTrack)
  }

  const triggerAudioTrackPause = (value) => {
    setPauseAudioTrack(value)
  }
  
  // CHANGE SLIDE (cta, stepper or swipe)
  const triggerStepChange = (slideIndex) => {
    setActiveSlideIndex(slideIndex)

    if (slideIndex === (data.length - 1) && !mutedAudioTrack) {
      setFadeAudioTrack(true);
    }
  }

  // START - initial cta clicked
  const onStartFunc = () => {
    setPlayAudioTrack(true);
    if (firstPlay) {
      setMutedAudioTrack(false);
      setFirstPlay(false);
      triggerTracking()
    }
    triggerStepChange(1);
  }

  // REFETCH - is triggered by user if they are on the error view
  const onRetry = () => {
    if (!grownUpId) {
      setDataLoading(true)
      fetchGrownUpFromToken()
    }

    if (kid_id) {
      setDataLoading(true)
      getRoundupData()
    }
  }

  
  // SWIPING - left
  const handleLeftSwipe = () => {
    const allow = activeSlideIndex !== 0 && (activeSlideIndex - 1 > -1);
    if (allow) {
      triggerStepChange(activeSlideIndex -1) 
    }
  }

  
  // SWIPING - right
  const handleRightSwipe = () => {
    const allow = activeSlideIndex !== slideTotal && (activeSlideIndex + 1 <= (slideTotal - 1)); 
    if (allow) {
      const startSlide = activeSlideIndex === 0 && firstPlay;
      if (!startSlide) {
        triggerStepChange(activeSlideIndex + 1)
      }
      triggerTracking()
    }
  }

  // FIRST SLIDE CLICK (fallback fix)
  const onSlideClickFunc = () => {
    if (data && slideTrackingData && firstPlay) {
      onStartFunc()
    }
  }
  
  // SET UP SWIPING 
  const handlers = useSwipeable({
    onSwipedLeft: () => handleRightSwipe(),
    onSwipedRight: () => handleLeftSwipe()
  });
  
  // SET UP SWIPING 
  const refPassthrough = (el) => {
    handlers.ref(el);
    roundupRef.current = el;
  }
 
  // BOTTOM NAV (prev and next)
  const renderNavControls = () => {
    const firstOrLast = activeSlideIndex === 0 || activeSlideIndex === (data.length -1);

    if (firstOrLast) return null;
    return (
      <div className="Roundup__navControls">
        <button type='button' onClick={handleLeftSwipe} className={`${containerClass}__navControls__prev`}>
          <span>‹</span>
          Prev
        </button>
        <button type='button' onClick={handleRightSwipe} className={`${containerClass}__navControls__next`}>
          Next
          <span>›</span>
        </button>
      </div>
    )
  }

  // TOP NAV (exit, mute and logo)
  const renderControls = () => {
    let muteButtonClass = (showSlides && !dataLoading) ? `${containerClass}__controls__mute` : 'SlideError__controls__mute';
    if (firstPlay) {
      muteButtonClass = (showSlides && !dataLoading) ? `${containerClass}__controls__hidden` : 'SlideError__controls__hidden';
    }
    return (
      <div className={`${containerClass}__controls`}>
        <button type='button' onClick={triggerClose} className={`${containerClass}__controls__exit`}>
          <ReactSVG src={exit} />
        </button>
        <ReactSVG className={`${containerClass}__controls__logo`} src={logo} />
        <button type='button' onClick={onToggleSound} className={muteButtonClass}>
          <ReactSVG src={mutedAudioTrack ? mute_on : mute_off} />
        </button>
      </div>
    )
}

  // LOADER
  const renderLoader = () => {
    return (
      <div className="SlideLoading">
        <Loader size="massive" active className="SlideLoading__loader" />
      </div>
    )
  }

  return (
    <div
      className={containerClass} 
      style={
        {
          backgroundImage: `url(${highlightSlide ? background_highlight : background})`,
        }
      }
    >
      <div className={`${containerClass}__wrapper`} {...handlers} ref={refPassthrough}>
        {renderControls()}
        {/* eslint-disable-next-line */}
        <div className={`${containerClass}__slides`} onClick={onSlideClickFunc}>
          {enableComingSoon && (
            <div className="SlideError">
              <p>Summer Rocksteady Round-up, coming soon!</p>
              <button type='button' onClick={triggerClose} className="SlideError__button">
                Back to Timeline
              </button>
            </div>
          )}
          {!enableComingSoon && showSlides && !dataLoading && releaseVersionTwo && (
            <SlidesV2 
              activeIndex={activeSlideIndex} 
              slidesData={data} 
              highlightVersion={highlightSlide} 
              grownUpId={grownUpId}
              kidId={kid_id}
              muted={mutedAudioTrack}
              triggerAudioTrackPause={triggerAudioTrackPause}
            />
          )}
          {dataLoading && renderLoader()}
          {!enableComingSoon && !showSlides && !dataLoading && (
            <div className="SlideError">
              <p>Oops, something went wrong.</p>
              <button type='button' onClick={onRetry} className="SlideError__button">
                Refresh
              </button>
            </div>
          )}
        </div>
        {!enableComingSoon && audioTrack && showSlides && (
          <Audio 
            play={playAudioTrack} 
            track={audioTrack} 
            fade={fadeAudioTrack} 
            muted={mutedAudioTrack}
            pause={pauseAudioTrack}
          />
        )}
        {!enableComingSoon && showSlides && !dataLoading && <Stepper activeStep={activeSlideIndex} stepCount={data.length} triggerChangeFunc={triggerStepChange} highlightVersion={highlightSlide} />}
        {!enableComingSoon && showSlides && renderNavControls()}
        {!enableComingSoon && showSlides && !dataLoading && activeSlideIndex === 0 && (
          <button type='button' id='start' onClick={onStartFunc} className={`${containerClass}__button__start`}>
            Tap to start
            <span>›</span>
          </button>
        )}
        {!enableComingSoon && activeSlideIndex === (slideTotal - 1) && (
          <button type='button' onClick={triggerClose} className={`${containerClass}__button__end`}>
            <Link to={paths.TIMELINE(kid_id)}>
              Go to Backstage
              <span>›</span>
            </Link>
          </button>
        )}
      </div>
    </div>
  )
}

export default RoundUp;
