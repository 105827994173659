import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types'
import SiteHeader from '../SiteHeader';
import Home from '../Home';
import JWTManager from '../../utils/JWTManager';
import WindowUtils from '../../utils/WindowUtils';
import paths from '../../consts/paths';
import GrownUpAPI from '../../api/GrownUpAPI';
import SiteFooter from '../SiteFooter/SiteFooter';

class App extends Component {
  static propTypes = {
    location: PropTypes.object,
  }

  static defaultProps = {
    location: { pathname: null },
  }

  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  componentDidMount() {
    this.fetchGrownUpFromToken();
  }

  initState = () => ({
    authorizationToken: JWTManager.getToken() || null,
    loading: true,
    grownUpAttributes: null
  });

  getAuthToken = () => {
    const authorizationToken = JWTManager.getToken();

    if (authorizationToken) {
      this.setState({ authorizationToken })
    } else {
      // if the user is a school and has found their way here by
      // mistake, redirect them
      const schoolsToken = JWTManager.getSchoolsToken();
      const { location } = this.props;
      const signIn = `${paths.SIGN_IN}?previousPath=${location.pathname + location.search}`
      const path = schoolsToken ? paths.SCHOOLS_ROOT : signIn;
      WindowUtils.replaceLocation(path);
    }
  }

  fetchGrownUpFromToken = async () => {
    try {
      const grownUp = await GrownUpAPI.fetchGrownUpFromToken();
      const { id, attributes } = grownUp.data.data;

      this.setState({
        grownUpAttributes: attributes,
        grownUpId: id,
        loading: false
      });
    } catch (err) {
      if (!err.message.match(/401/)) {
        if (err?.response?.data?.errors === 'Error finding Grown Up') {
          JWTManager.removeToken();
          localStorage.clear();
        }

        this.setState(() => {
          throw err;
        });
      }
    }
  };

  checkUpdateInProgress = () => {
    const { loading, grownUpAttributes } = this.state;

    if (loading) {
      return false;
    }

    return grownUpAttributes.enrolments_disabled
  };

  home = () => {
    if (this.checkUpdateInProgress()) {
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={14}>
              <p />
              <h1>Sorry, we are currently updating our system</h1>
              <p>
                Please check back tomorrow, or if you have an urgent query you can contact the
                office by calling
                {' '}
                <a href="tel:+443301130330">0330 113 0330</a>
                {' '}
                (Monday-Friday, 9am-5pm) or email us at
                {' '}
                <a href="mailto:info@rocksteadymusicschool.com">info@rocksteadymusicschool.com</a>
                .
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }

    const {loading, grownUpId, grownUpAttributes} = this.state;

    if (loading) {
      return null;
    }

    return (
      <Home grownUpId={grownUpId} grownUpAttributes={grownUpAttributes} refreshGrownUp={this.fetchGrownUpFromToken} />
    );
  };

  siteHeader = () => {
    const {grownUpId} = this.state;
    let signedIn = false;
    if (grownUpId) {
      signedIn = true;
    }
    return (<SiteHeader signedIn={signedIn} />)
  }

  render() {
    const {authorizationToken} = this.state;

    if (!authorizationToken) {
      this.getAuthToken();
    };

    return (
      <>
        {this.siteHeader()}
        <Container className="site-container app">
          {this.home()}
        </Container>
        <SiteFooter />
      </>
    );
  }
}

export default App;
