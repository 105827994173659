import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';
import { RoundUpHelper, RoundUpTrackingSetUp, RoundUpTrackingUpdate } from "../data/roundUp/RoundUpHelper";

class RoundUpAPI {
  getRoundUp = async (id) => {
    const releaseVersionTwo = process.env.REACT_APP_PHASE_2_ROUNDUP === 'true';
    const response = await AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/v1/kids/${id}/round_up`
    });
    return RoundUpHelper(response.data, releaseVersionTwo);
  }
  
  postRoundUpViewing = async (slide_data, grown_up_id, round_up_id) => {
    const trackingSetUpData = await RoundUpTrackingSetUp(slide_data, grown_up_id, round_up_id);
    const response = await AuthenticatedHTTPWrapper.post({
        url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/round_up_viewings/`,
        config: { data: trackingSetUpData }
      })
    return response.data;
  };

  updateRoundUpViewing = (tracking_id, slide_designator, slide_number) => {
    if (!tracking_id) return null;
    const viewingData = RoundUpTrackingUpdate(slide_designator, slide_number);
    return AuthenticatedHTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/round_up_viewings/${tracking_id}`,
      data: viewingData
    })
  };
}

export default new RoundUpAPI();
