import React from 'react';
import PropTypes from 'prop-types';
import './Stepper.scss';

const Stepper = ({activeStep, stepCount, triggerChangeFunc, highlightVersion, disabledNavigation}) => {
  const steps = [];
  const containerClass = highlightVersion ? 'StepperHighlight'  : 'Stepper';
  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < stepCount; i++) {
    const isActive = activeStep === i;

    if (disabledNavigation) {
      steps.push(
        <span
          className={isActive ? `${containerClass}__activeStep` : `${containerClass}__step`} 
          type='button'
          key={i}
        />
      )
    } else {
      steps.push(
        <button
          className={isActive ? `${containerClass}__activeStep` : `${containerClass}__step`} 
          onClick={() => triggerChangeFunc(i)}
          onKeyDown={() => triggerChangeFunc(i)}
          type='button'
          key={i}
        />
      )
    }
  }

  return (
    <div className={containerClass}>
      {steps.map((step) => {
        return step;
      })}
    </div>
  )
}

export default Stepper;

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  triggerChangeFunc: PropTypes.func.isRequired,
  highlightVersion: PropTypes.bool,
  disabledNavigation: PropTypes.bool
}

Stepper.defaultProps = {
  highlightVersion: false,
  disabledNavigation: true
}
