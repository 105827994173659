import HTTPWrapper from '../utils/HTTPWrapper';
import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';

class UserAPI {
  signIn = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/users/sign_in.json`,
    config,
  });

  signOut = config => HTTPWrapper.delete({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/users/sign_out.json`,
    config,
  });

  createUser = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/users/register_external_user.json`,
    config,
  });

  setPasswordForUser = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/users/set_password.json`,
    config,
  });

  updatePasswordForUser = config => AuthenticatedHTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/users/update_password.json`,
    config,
  });

  sendResetPasswordInstructions = config => AuthenticatedHTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/users/passwords.json`,
    config,
  });

  unlockAccount = token => HTTPWrapper.get({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/users/unlock?unlock_token=${token}`
  });

  resendUnlockInstructions = config => HTTPWrapper.post({
    url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/users/unlock`,
    config
  });
}

export default new UserAPI();
