import bassBand from '../../../components/RoundUp/v2/assets/kid_assets/bass/Band-Bass.svg';
import bassConcert from '../../../components/RoundUp/v2/assets/kid_assets/bass/Concert-Bass.svg';
import bassSolo from '../../../components/RoundUp/v2/assets/kid_assets/bass/Solo-Bass.svg';

import guitarBand from '../../../components/RoundUp/v2/assets/kid_assets/guitar/Band-Guitar.svg';
import guitarConcert from '../../../components/RoundUp/v2/assets/kid_assets/guitar/Concert-Guitar.svg';
import guitarSolo from '../../../components/RoundUp/v2/assets/kid_assets/guitar/Solo-Guitar.svg';

import keyboardBand from '../../../components/RoundUp/v2/assets/kid_assets/keyboard/Band-Keyboard.svg';
import keyboardConcert from '../../../components/RoundUp/v2/assets/kid_assets/keyboard/Concert-Keyboard.svg';
import keyboardSolo from '../../../components/RoundUp/v2/assets/kid_assets/keyboard/Solo-Keyboard.svg';

import vocalsBand from '../../../components/RoundUp/v2/assets/kid_assets/vocals/Band-Vocal.svg';
import vocalsConcert from '../../../components/RoundUp/v2/assets/kid_assets/vocals/Concert-Vocal.svg';
import vocalsSolo from '../../../components/RoundUp/v2/assets/kid_assets/vocals/Solo-Vocal.svg';

import drumsBand from '../../../components/RoundUp/v2/assets/kid_assets/drums/Band-Drums.svg';
import drumsConcert from '../../../components/RoundUp/v2/assets/kid_assets/drums/Concert-Drums.svg';
import drumsSolo from '../../../components/RoundUp/v2/assets/kid_assets/drums/Solo-Drums.svg';

import song from '../../../components/RoundUp/v2/assets/song.svg';
import videoClapper from '../../../components/RoundUp/v2/assets/video_clapper.svg';

import trinity_info_thumbnail from '../../../components/RoundUp/v2/assets/trinity_info_video.png';

const instrumentToRole = {
  "bass": "Bassist",
  "vocals": "Vocalist",
  "guitar": "Guitarist",
  "keyboard": "Keyboardist",
  "drums": "Drummer"
}

const kidInstrumentAssets = {
  "bass": {
    "band": bassBand,
    "concert": bassConcert,
    "solo": bassSolo
  },
  "drums": {
    "band": drumsBand,
    "concert": drumsConcert,
    "solo": drumsSolo
  },
  "guitar": {
    "band": guitarBand,
    "concert": guitarConcert,
    "solo": guitarSolo
  },
  "keyboard": {
    "band": keyboardBand,
    "concert": keyboardConcert,
    "solo": keyboardSolo
  },
  "vocals": {
    "band": vocalsBand,
    "concert": vocalsConcert,
    "solo": vocalsSolo
  }
}

const concertName = {
  "spring": "Easter",
  "summer": "Summer",
  "autumn": "Christmas"
}

const ordering = {
  intro: 1,
  band_role: 2,
  media_1: 3,
  lesson_attendance: 4,
  concert_attendance: 5,
  current_song: 6,
  skills_completed_since_last_roundup: 7,
  skills_in_progress: 8,
  skills_upcoming: 9,
  skills_completed: 10,
  trinity_enrolled: 11,
  trinity_info: 12,
  upcoming_concert: 13,
  kid_photo: 14,
  media_2: 15,
  media_3: 16,
  coming_soon: 17,
  outro: 18
}

const skillListItemClassSuffix = (skillDescription) => {
  let suffix = '__small';

  if (skillDescription.length > 168) {
    suffix = '__huge';
  } else if (skillDescription.length > 136) {
    suffix = '__large';
  } else if (skillDescription.length > 46) {
    suffix = '__medium';
  }

  return suffix;
};
  
const translateToSlide = (response) => {
  const {
    data
  } = response;

  if (!data) return null;

  const {
    id,
    attributes,
  } = data;
  
  const {
    ac_year,
    ac_year_period,
    latest_band_media
  } = attributes

  const attributeData = attributes.data;

  const {
    kid_name,
    instrument,
    band_name,
    lessons_count,
    concerts_count,
    skills_completed,
    skills_completed_since_last_roundup,
    skills_in_progress,
    skills_upcoming,
    song_title,
    song_artist,
    next_concert_planned,
    trinity_enrolled,
    stage_progress_percent,
  } = attributeData;

  if (!kid_name) return null;

  const band_role = instrument && instrumentToRole[instrument];
  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""
  const hasDateInfo = ac_year_period && ac_year;
  // const year = hasDateInfo && ac_year_period !== 'autumn' ? ac_year + 1 : ac_year;
  const date = hasDateInfo && capitalize(`${ac_year_period}`);
  const nextConcertName = hasDateInfo && concertName[ac_year_period];
  const slides = [];
  const slideDesignators = [];

  // PRELOAD BAND IMAGES
  if (latest_band_media) {
    latest_band_media.forEach((media) => {
      const img = new Image();
      img.src = media.photo_url;
    });
  };

  // INTRO
  if (date && band_role) {
    const lengthOfName = kid_name.length;
    
    const checkLength = (length) => {
      let styleAddition = '';
      // 2 characters are for the 's
      if (length < 4) {
        styleAddition = '__larger';
      }
      if (length > 3 && length < 6) {
        styleAddition = '__medium';
      }
      return styleAddition;
    }

    const title_class = lengthOfName < 10 ? `title-large_typing${checkLength(lengthOfName)}` : "title_typing-large";
    const intro_data = {
      "order": ordering.intro,
      "name": "intro",
      "tracking": "intro",
      "sections": {
        1: [
          {[title_class]: `${kid_name}'s`},
          {"title-strong": date},
          {"subTitle": 'Half-term'},
          {"subTitle-emphasis": "Round-up"}
        ],
        2: [],
        3: [
          {"image_src_sparkles": kidInstrumentAssets[instrument].solo},
          {"big_flowers": true}
        ],
        4: [], 
        5: [
          {"body": `See <span>${kid_name}'s</span> amazing achievements and how they're progressing...`},
        ]
      }
    };
    slides.push(intro_data);
  }

  // BAND ROLE
  if (band_role && band_name) {
    const band_role_data = {
      "order": ordering.band_role,
      "name": "band_role",
      "tracking": "band_role",
      "sections": {
        1: [
          {"title": kid_name},
          {"micro": "is a"},
          {"subTitle-emphasis": band_role},
          {"micro": "in the band..."},
        ],
        2: [],
        3: [
          {"image_src_flowers": kidInstrumentAssets[instrument].solo},
        ],
        4: [
          {"head-emphasis": band_name}
        ],
        5: []
      }
    };
    slides.push(band_role_data);
  } else if (band_role && !band_name) {
    const band_role_data_fallback = {
      "order": ordering.band_role,
      "name": "band_role_fallback",
      "tracking": "band_role_fallback",
      "sections": {
        1: [
          {"title": kid_name},
          {"micro": "is a"},
          {"subTitle-emphasis": band_role},
        ],
        2: [],
        3: [
          {"image_src_flowers": kidInstrumentAssets[instrument].solo},
        ],
        4: [],
        5: []
      }
    };
    slides.push(band_role_data_fallback);
  }

  // LESSON ATTENDANCE
  if (lessons_count && instrument && lessons_count > 0) {
  const lesson_attendance_data = {
    "order": ordering.lesson_attendance,
    "name": "lesson_attendance",
    "tracking": "lesson_attendance",
    "sections": {
      1: [
        {"body": `<span>${kid_name}</span> has spent a total of...`},
      ],
      2: [],
      3: [
        {"image_src_flowers": kidInstrumentAssets[instrument].band},
      ],
      4: [
        {"unit": lessons_count * 30},
        {"measurement": "minutes"},
      ],
      5: [
        {"body": "...in band practice since starting Rocksteady."}
      ] 
    }
  };
  slides.push(lesson_attendance_data);
  }

  // CONCERT ATTENDANCE
  if (concerts_count && concerts_count > 0 && instrument) {
    const concert_attendance_data = {
      "order": ordering.concert_attendance,
      "name": "concert_attendance",
      "tracking": "concert_attendance",
      "sections": {
        1: [
          {"body": `So far, <span>${kid_name}</span> has performed in...`},
        ],
        2: [
          {"unit": concerts_count},
          {"measurement": `concert${concerts_count > 1 ? 's' : ''}`},
        ],
        3: [
          {"image_src_sparkles": kidInstrumentAssets[instrument].concert},
        ],
        4: [
          {"head-small": "Awesome!"},
        ],
        5: [
          {"body": "Playing an instrument in a band encourages new friendships and helps children to develop teamwork skills."}
        ] 
      }
    };
    slides.push(concert_attendance_data);
  } else if ((!concerts_count || concerts_count < 1) && instrument) {
      const concert_attendance_data_fallback = {
        "order": ordering.concert_attendance,
        "name": "concert_attendance_fallback",
        "tracking": "concert_attendance_fallback",
        "sections": {
          1: [
            {"body": `<span>${kid_name}</span> is currently working towards their...`},
          ],
          2: [
            {"unit": "First"},
            {"measurement": "concert"},
          ],
          3: [
            {"image_src_sparkles": kidInstrumentAssets[instrument].concert},
          ],
          4: [
            {"head-small": "Awesome!"},
          ],
          5: [
            {"body": "Playing an instrument in a band encourages new friendships and helps children to develop teamwork skills."}
          ]
        }
      };
      slides.push(concert_attendance_data_fallback);
  }

  // CURRENT SONG
  if (song_title && song_artist) {
    const current_song_data = {
      "order": ordering.current_song,
      "name": "current_song",
      "tracking": "current_song",
      "sections": {
        1: [
          {"body": `<span>${kid_name}</span> has been learning...`},
        ],
        2: [
          {"title-large-alt": song_artist},
          {"head-emphasis": song_title},
        ],
        3: [
          {"image_src": song},
        ],
        4: [],
        5: [
          {"body": "What a tune!"}
        ] 
      }
    };
    slides.push(current_song_data);
  } else {
    const current_song_fallback_data = {
      "order": ordering.current_song,
      "name": "current_song_fallback",
      "tracking": "current_song_fallback",
      "sections": {
        1: [],
        2: [
          {"title": kid_name},
          {"body": `will soon pick a song to learn in their band...`},
        ],
        3: [
          {"image_src": song},
        ],
        4: [],
        5: [
          {"body": "...from hundreds of carefully selected tunes!"}
        ] 
      }
    }

    slides.push(current_song_fallback_data);
  }

  // SKILLS COMPLETE (since last roundup) - LISTED 
  if (skills_completed_since_last_roundup && skills_completed_since_last_roundup.length > 0) {
  const first = skills_completed_since_last_roundup[0] ? skills_completed_since_last_roundup[0] : '';
  const second = skills_completed_since_last_roundup[1] ? skills_completed_since_last_roundup[1] : '';
  const third = skills_completed_since_last_roundup[2] ? skills_completed_since_last_roundup[2] : '';

  const first_class = first.length > 1 ? `body-strong_star${skillListItemClassSuffix(first)}` : "body-strong";
  const second_class = second.length > 1 ? `body-strong-alt_star${skillListItemClassSuffix(second)}` : "body-strong-alt";
  const third_class = third.length > 1 ? `body-strong_star${skillListItemClassSuffix(third)}` : "body-strong";

  const skills_completed_since_last_roundup_data_specific = {
    "order": ordering.skills_completed_since_last_roundup,
    "name": "skills_completed_since_last_roundup",
    "tracking": "skills_completed_since_last_roundup",
    "sections": {
      1: [
        {"title-large": "Skills"},
        {"title-strong": "Complete"},
        {"body-emphasis": `So far this term <span>${kid_name}</span> has learnt the following skills...`},
      ],
      2: [],
      3: [],
      4: [
        {[first_class]: `${first}`},
        {[second_class]: `${second}`},
        {[third_class]: `${third}`},
      ],
      5: [
        {"head-small-body": `Great work!`}
      ] 
    }
  };
  slides.push(skills_completed_since_last_roundup_data_specific);
  }

  // SKILLS IN PROGRESS
  if (skills_in_progress && skills_in_progress.length > 0) {
    const first = skills_in_progress[0] ? skills_in_progress[0] : '';
    const second = skills_in_progress[1] ? skills_in_progress[1] : '';
    const third = skills_in_progress[2] ? skills_in_progress[2] : '';

    const first_class = first.length > 1 ? `body-strong_star${skillListItemClassSuffix(first)}` : "body-strong";
    const second_class = second.length > 1 ? `body-strong-alt_star${skillListItemClassSuffix(second)}` : "body-strong-alt";
    const third_class = third.length > 1 ? `body-strong_star${skillListItemClassSuffix(third)}` : "body-strong";

    const skills_in_progress_data_specific = {
      "order": ordering.skills_in_progress,
      "name": "skills_in_progress",
      "tracking": "skills_in_progress",
      "sections": {
        1: [
          {"title-large": "Skills"},
          {"title-strong": "In Progress"},
          {"body-emphasis": `<span>${kid_name}</span>'s been working on these cool things this half-term...`},
        ],
        2: [],
        3: [],
        4: [
          {[first_class]: `${first}`},
          {[second_class]: `${second}`},
          {[third_class]: `${third}`},
        ],
        5: [
          {"head-small-body": `Keep on!`}
        ] 
      }
    };
    slides.push(skills_in_progress_data_specific);
  } else if (!skills_in_progress || !skills_in_progress.length) {
    const skills_in_progress_data_fallback = {
      "order": ordering.skills_in_progress,
      "name": "skills_in_progress_fallback",
      "tracking": "skills_in_progress_fallback",
      "sections": {
        1: [
          {"title-large": "Skills"},
          {"body-emphasis": `<span>${kid_name}</span> will learn new skills in every lesson, across...`},
        ],
        2: [],
        3: [],
        4: [
          {"body-strong_star": `Instrument`},
          {"body-strong-alt_star": `Song`},
          {"body-strong_star": `Musicianship`},
          {"body-strong-alt_star": `Band`},
        ],
        5: [
          {"head-small-emphasis": `Way to go!`}
        ]
      }
    };
    slides.push(skills_in_progress_data_fallback);
  }

  // SKILLS UPCOMING - LISTED 
  if (skills_upcoming && skills_upcoming.length > 0) {
    const first = skills_upcoming[0] ? skills_upcoming[0] : '';
    const second = skills_upcoming[1] ? skills_upcoming[1] : '';
    const third = skills_upcoming[2] ? skills_upcoming[2] : '';
  
    const first_class = first.length > 1 ? `body-strong_star${skillListItemClassSuffix(first)}` : "body-strong";
    const second_class = second.length > 1 ? `body-strong-alt_star${skillListItemClassSuffix(second)}` : "body-strong-alt";
    const third_class = third.length > 1 ? `body-strong_star${skillListItemClassSuffix(third)}` : "body-strong";

    const skills_upcoming_roundup_data_specific = {
      "order": ordering.skills_upcoming,
      "name": "skills_upcoming",
      "tracking": "skills_upcoming",
      "sections": {
        1: [
          {"title-large": "Skills"},
          {"title-strong": "Upcoming"},
          {"body-emphasis": `Here's what's coming next for <span>${kid_name}</span>...`},
        ],
        2: [],
        3: [],
        4: [
          {[first_class]: `${first}`},
          {[second_class]: `${second}`},
          {[third_class]: `${third}`},
        ],
        5: [
          {"head-small-body": `Loads more fun!`}
        ] 
      }
    };

    slides.push(skills_upcoming_roundup_data_specific);
  }

  // SKILLS COMPLETED (accumulative) - COUNT
  if (skills_completed && skills_completed >= 5) {
    const skills_completed_data = {
      "order": ordering.skills_completed,
      "name": "skills_completed",
      "tracking": "skills_completed",
      "sections": {
        1: [
          {"body": `So far, <span>${kid_name}</span> has learnt a total of...`},
        ],
        2: [
          {"unit": skills_completed},
          {"measurement": "skills"},
        ],
        3: [],
        4: [],
        5: [
          {"body": "...across our curriculum, in addition to the wellbeing benefits of being in a band."},
        ] 
      }
    };
    slides.push(skills_completed_data);
  } else if (!skills_completed || skills_completed < 5) {
    const skills_completed_data_fallback = {
      "order": ordering.skills_completed,
      "name": "skills_completed_fallback",
      "tracking": "skills_completed_fallback",
      "sections": {
        1: [
          {"body": `<span>${kid_name}</span> is ready to rock! Rocksteady's curriculum consists of up to...`},
        ],
        2: [
          {"unit": 300},
          {"measurement": "skills"},
        ],
        3: [],
        4: [],
        5: [
          {"body": `...in addition to the wellbeing benefits of being in a band.`}
        ] 
      }
    }
    slides.push(skills_completed_data_fallback);
  }

  // TRINITY ENROLLMENT
  if (trinity_enrolled && stage_progress_percent) {
    if (stage_progress_percent <= 33) {
      const trinity_progress_low = {
        "order": ordering.trinity_enrolled,
        "name": "trinity_progress_low",
        "tracking": "trinity_progress_low",
        "sections": {
          1: [
            {"body": `What's more...`},
          ],
          2: [],
          3: [
            {"progress": stage_progress_percent},
          ],
          4: [
            {"title-large": 'Awesome effort!'}
          ],
          5: [
            {"body": `<span>${kid_name}</span> has started working towards their exam-free <bold>Music Performance in Bands</bold> Ofqual qualification.`}
          ] 
        }
      }
      slides.push(trinity_progress_low);
    }

    else if (stage_progress_percent > 33 && stage_progress_percent < 65) {
      const trinity_progress_medium = {
        "order": ordering.trinity_enrolled,
        "name": "trinity_progress_medium",
        "tracking": "trinity_progress_medium",
        "sections": {
          1: [
            {"body": `What's more...`},
          ],
          2: [],
          3: [
            {"progress": stage_progress_percent},
          ],
          4: [
            {"title-large": 'Way to go!'}
          ],
          5: [
            {"body": `<span>${kid_name}</span> is already half way through their exam-free <bold>Music Performance in Bands</bold> Ofqual qualification.`}
          ] 
        }
      }
      slides.push(trinity_progress_medium);
    }

    else if (stage_progress_percent >= 65) {
      const trinity_progress_high = {
        "order": ordering.trinity_enrolled,
        "name": "trinity_progress_high",
        "tracking": "trinity_progress_high",
        "sections": {
          1: [
            {"body": `What's more...`},
          ],
          2: [],
          3: [
            {"progress": stage_progress_percent},
          ],
          4: [
            {"title-large": 'Keep it going!'}
          ],
          5: [
            {"body": `<span>${kid_name}</span> has very nearly completed their exam-free <bold>Music Performance in Bands</bold> Ofqual qualification.`}
          ] 
        }
      }
      slides.push(trinity_progress_high);
    }
  }

  // TRINITY INFO
  if (trinity_enrolled) {
    const trinity_info_data = {
      "order": ordering.trinity_info,
      "name": "trinity_info",
      "tracking": "trinity_info",
      "sections": {
        1: [
          {"head-small": 'Want to know more?'},
        ],
        2: [
          {"body": "We've created a first of its kind, exam-free award..."},
        ],
        3: [
          {"video": 'https://vimeo.com/870571499/f1690e22e2', "thumbnail_src": trinity_info_thumbnail},
        ],
        4: [],
        5: [
          {"body": `When <span>${kid_name}</span> has achieved enough skills, their certificate will land in your inbox!`}
        ] 
      }
    };
    slides.push(trinity_info_data);
  }

  // UPCOMING CONCERT
  if (next_concert_planned && nextConcertName && instrument) {
    const upcoming_concert_data = {
      "order": ordering.upcoming_concert,
      "name": "upcoming_concert",
      "tracking": "upcoming_concert",
      "sections": {
        1: [],
        2: [
          {"title-large": `${nextConcertName} concert`},
        ],
        3: [
          {"image_src_sparkles": kidInstrumentAssets[instrument].concert},
        ],
        4: [
          {"head-small-highlight": `It's booked!`},
        ],
        5: [
          {"body": `<span>${kid_name}</span> is performing at the ${nextConcertName} concert soon. We can't wait to see you there!`}
        ] 
      }
    };
    slides.push(upcoming_concert_data);
  } else if (!next_concert_planned && nextConcertName && instrument) {
    const upcoming_concert_data = {
      "order": ordering.upcoming_concert,
      "name": "upcoming_concert_fallback",
      "tracking": "upcoming_concert_fallback",
      "sections": {
        1: [],
        2: [
          {"title-large": `${nextConcertName} concert`},
        ],
        3: [
          {"image_src_sparkles": kidInstrumentAssets[instrument].concert},
        ],
        4: [
          {"head-small": "Coming soon!"},
        ],
        5: [
          {"body": `<span>${kid_name}</span> will be performing at a concert soon — we'll let you know details when it's booked.`}
        ] 
      }
    };
    slides.push(upcoming_concert_data);
  }

  // MEDIA
  if (latest_band_media && latest_band_media.length) {
    const limitedToTwo = [...latest_band_media].slice(0, 2)
    limitedToTwo.forEach((m, index) => {
      const copy = {
        0: {
          'title': 'Practice makes Perfect',
          'subTitle': 'Rocking out, sounding great!',
          'tracking': 'latest_media'
        },
        1: {
          'title': 'Rocking Out',
          'subTitle': 'Band lessons are full of fun and creativity!',
          'tracking': 'past_media'
        }
      }
      const mediaData = {
        "order": ordering[`media_${index + 1}`],
        "name": "media",
        "tracking": copy[index].tracking,
        "sections": {
          1: [
            {"title-large": copy[index].title},
            {"body-highlight": copy[index].subTitle},
          ],
          2: [],
          3: [
            {"image_src": m.photo_url},
            {"report": m.photo_media_submission_id},
            {"body": `Band Rehearsal — ${m.date}`},
          ],
          4: [],
          5: []
        }
      }
      slides.push(mediaData);
    })
  }

  // COMING SOON - lesson videos
  if (latest_band_media && latest_band_media.length) {
    const comsingSoonData = {
      "order": ordering.coming_soon,
      "name": "coming_soon",
      "tracking": "coming_soon",
      "sections": {
        1: [
          {"title-large": "Lesson Videos"},
          {"head-small-emphasis": "Coming Soon"},
        ],
        2: [],
        3: [
          {"image_src": videoClapper},
        ],
        4: [],
        5: [
          {"body": `We're working hard behind the scenes to bring you videos of your child practising in lessons.`}
        ]
      }
    };
    slides.push(comsingSoonData);
  }
   
  // OUTRO
  if (instrument) {
    const outroData = {
      "order": ordering.outro,
      "name": "outro",
      "tracking": "outro",
      "sections": {
        1: [
          {"head-small-emphasis": "Keep rocking..."},
          {"title-large_typing": kid_name}
        ],
        2: [],
        3: [
          {"image_src_sparkles": kidInstrumentAssets[instrument].solo},
          {"big_flowers": true}
        ],
        4: [],
        5: [
          {"body": `Such amazing progress so far! Keep an eye out for the next <span>Half-term Round-up.</span>`}
        ]
      }
    };
    slides.push(outroData);
  }

  slides.sort((a,b) => a.order - b.order)
  slides.forEach((slide) => {
    slideDesignators.push(slide.tracking)
  })

  return  {
    id,
    slides,
    tracking: {
      slides_count: slides.length,
      slide_designators: slideDesignators
    }
  }
}

export default translateToSlide;
